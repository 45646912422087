import { PageType, UseCase } from "@entities/enums";

type LayoutState = {
  options?: { useCase: UseCase; pageType: PageType };
};

const initialState: LayoutState = {};

export default (state = initialState) => {
  return state;
};
