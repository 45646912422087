import { useState, useEffect } from "react";

const getWindowWidth = () => (typeof window !== "undefined" && window.innerWidth) || 0;

const useScreenWidth = () => {
  const [width, setWidth] = useState<number>(getWindowWidth());

  const handleWindowSizeChange = () => {
    setWidth(getWindowWidth());
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowSizeChange);

      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
    return () => false;
  }, []);

  return { isXSmall: width <= 360, isSmall: width <= 768, width };
};

export default useScreenWidth;
