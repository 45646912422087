import React, { useCallback, useContext } from "react";
import classnames from "classnames";

import { ThemeContext } from "@frontend/app/context";
import useTranslation, { TranslationKey } from "@frontend/app/hooks/useTranslation";
import Text from "@frontend/app/components/Text";

type Props = {
  title: TranslationKey;
  header?: TranslationKey;
};

const Title: React.FC<Props> = ({ title, header }) => {
  const { classNames } = useContext(ThemeContext);
  const translation = useTranslation();
  const titleRef = useCallback((node) => {
    if (node === null) {
      return;
    }
    node.focus();
  }, []);

  return (
    <div className={classnames("title", "main__content-header")}>
      <div className={classnames("title__section", "main__content-header--wrapper")}>
        {header && translation(header) ? (
          <p
            tabIndex={0}
            className={classnames("text-small", "main__content-header__customerId", classNames.textSmall)}
          >
            <Text translationKey={header} />
          </p>
        ) : null}
        {translation(title)?.trim() ? (
          <h1
            tabIndex={-1}
            className={classnames("text-h1", "main__content-header__title", classNames.header1)}
            ref={titleRef}
          >
            <Text translationKey={title} />
          </h1>
        ) : null}
      </div>
    </div>
  );
};
export default Title;
