import React from "react";

export const classNames = {
  main: "",
  header1: "",
  header2: "",
  header3: "",
  header4: "",
  header5: "",
  text: "",
  textSecondary: "",
  textSmall: "",
  textSmallSecondary: "",
  textEmphasized: "",
  textGreetings: "",
  link: "",
  list: "",
  btnCloseLayout: "",
  primaryButton: "",
  secondaryButton: "",
  stdButton: "",
  linkButton: "",
  label: "",
  input: "",
  textArea: "",
  checkMark: "",
};

export type ThemeComponents = {
  ProgressPage?: React.FC;
  MainMessage?: React.FC<any>;
  Icon?: React.FC<any>;
  ModalContent?: React.FC<any>;
};

export type ThemeContextProps = {
  classNames: Record<string, string>;
  components: ThemeComponents;
  actions: {
    addClassName: (classKey: string, className: string) => void;
  };
};

export default React.createContext<ThemeContextProps>({
  classNames,
  components: {},
  actions: { addClassName: () => null },
});
