import React from "react";
import FormatterService from "@common/Formatter";

import LogService from "../services/Log";
import PlaceholderService from "../services/Placeholder";

type LocalizationContextProps = {
  logService: LogService;
  placeholderService: PlaceholderService;
  formatterService: FormatterService;
};

export default React.createContext<LocalizationContextProps>({} as any);
