import { SET_APP_CONFIG } from "../actionTypes";

const initialState = {
  sandbox: false, // used for Preview mode
  pageToken: null,
  locale: "",
  csrfToken: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
