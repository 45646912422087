import React from "react";
import parseHTML from "html-react-parser";
import { usePlaceholderResolver, useTranslation, TranslationKey } from "@frontend/app/hooks";

interface TextTranslationProps {
  translationKey: TranslationKey;
  fallbackText?: string;
  computedPlaceholders?: Record<string, any>;
  children?: never;
}

interface TextPlaceholderProps {
  children: React.ReactNode;
  computedPlaceholders?: Record<string, any>;
  translationKey?: never;
  fallbackText?: never;
}

type Props = TextTranslationProps | TextPlaceholderProps;

const Text: React.FC<Props> = ({ children, translationKey, fallbackText, computedPlaceholders = {} }) => {
  const placeholderResolver = usePlaceholderResolver(computedPlaceholders);
  const translation = useTranslation(computedPlaceholders);

  let text;

  if (children) {
    [text] = placeholderResolver([children]);
  } else if (translationKey) {
    text = translation(translationKey);

    if (text === undefined) {
      return translationKey;
    }

    if (text.length === 0 || text.trim().length === 0) {
      return fallbackText;
    }
  }

  if (typeof text === "string") {
    return parseHTML(text);
  }

  return text;
};

export default Text;
