import { useEffect } from "react";
import UrlParse from "url-parse";

export default function useQueryLocale(locale) {
  useEffect(() => {
    if (typeof window !== "undefined" && !window.location.search.includes("locale")) {
      try {
        const url = UrlParse(window.location.href, true);
        const queryData = url.query;
        queryData.locale = locale;
        url.set("query", queryData);

        const newUrl = UrlParse(url.toString());

        const { history = {} as any } = window;
        if (!history.replaceState) {
          window.location.search = newUrl.query;
          return;
        }

        history.replaceState({}, undefined, newUrl.toString());
      } catch (error) {
        if (typeof (window as any).ineum !== "undefined") {
          (window as any).ineum("reportError", error);
        }
      }
    }
  }, []);
}
