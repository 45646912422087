import { useSelector, shallowEqual } from "react-redux";
import React, { useMemo } from "react";
import FormatterService from "@common/Formatter";
import { APIContext, LocalizationContext } from "./context";
import Head from "./components/Head";
import BaseLayout from "./layouts";
import BasePage from "./pages";
import PlaceholderService from "./services/Placeholder";
import { useInstana, useQueryLocale, useBrowserTabSync } from "./hooks";
import LogService from "./services/Log";
import ApiService from "./services/Api";
import { RootState } from "./store/reducers";

const App = () => {
  // We need the use shallowEqual because the default implementation of "redux hooks" doesn't  compare objects.
  // ref: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const { pageToken, pageType, useCase, locale, hostUrl, csrfToken, formats, sandbox, instana, referenceNumber } =
    useSelector(mapState, shallowEqual);

  const apiContextValue = useMemo(() => {
    const apiService = new ApiService({ csrfToken, hostUrl, sandbox });
    return {
      apiService,
    };
  }, [csrfToken, hostUrl, sandbox]);

  const localizationContextValue = useMemo(() => {
    const logService = new LogService(apiContextValue);
    const formatterService = new FormatterService(locale, formats);
    const placeholderService = new PlaceholderService({
      formatterService,
    });
    return {
      logService,
      formatterService,
      placeholderService,
    };
  }, [apiContextValue, locale, formats]);

  useQueryLocale(locale);
  useInstana({ ...instana, pageToken, pageType, useCase });
  useBrowserTabSync({ pageToken, pageType, referenceNumber, useCase });

  return (
    <APIContext.Provider value={apiContextValue}>
      <LocalizationContext.Provider value={localizationContextValue}>
        <Head />
        <BaseLayout>
          <BasePage />
        </BaseLayout>
      </LocalizationContext.Provider>
    </APIContext.Provider>
  );
};

function mapState({
  formats,
  layout: { options },
  theme: { name: themeName },
  viewData: { claim, merchant },
  appConfig: { pageToken, hostUrl, csrfToken, sandbox, instana, locale },
}: RootState) {
  return {
    pageToken,
    locale,
    hostUrl,
    formats,
    instana,
    csrfToken,
    sandbox,
    themeName,
    useCase: options?.useCase,
    pageType: options?.pageType,
    currency: claim?.currency,
    merchantName: merchant?.name,
    referenceNumber: claim?.referenceNumber,
  };
}

export default App;
