import { SET_COMPONENTS, TOGGLE_CLAIM_DETAILS } from "../actionTypes";
import { ComponentsState } from "../../types";

export const componentInitialState: ComponentsState = {
  claimDetails: {
    enabled: false,
    collapse: false,
    itemDateField: "",
  },
  attachments: {},
  paymentSelector: {
    separateSepaTransfer: false,
  },
  sepaTicket: {},
  paymentModalitiesMessage: {},
  paymentModalitiesOptions: {},
  installmentPlan: {},
  installmentPlanSepaMandate: {},
  generalFeedback: {},
  customerScore: {},
  disputeClaim: {},
  contactData: {},
  confirmation: {},
  emailContactCollection: {},
  mobileContactCollection: {},
};

export default (state = componentInitialState, action): ComponentsState => {
  switch (action.type) {
    case SET_COMPONENTS: {
      const { payload: components } = action;
      const newState = { ...state };
      Object.keys(components).forEach((componentName) => {
        newState[componentName] = {
          ...newState[componentName],
          ...components[componentName],
        };
      });
      return newState;
    }
    case TOGGLE_CLAIM_DETAILS: {
      const { claimDetailsConfig } = action.payload;
      return {
        ...state,
        claimDetails: {
          ...state.claimDetails,
          collapse: !claimDetailsConfig.collapse,
        },
      };
    }
    default:
      return state;
  }
};
