import {
  CollectedCustomerScore,
  Dispute,
  DisputeOption,
  InstallmentPlan,
  InstallmentPlanOptions,
  Merchant,
  PayableStatus,
  PaymentModality,
  PaymentModalityRequest,
  PaymentProviderConfiguration,
  PaymentRequest,
  PaymentSettings,
  PlaceHolder,
  ViewData,
  ViewDataAttachment,
  ViewDataError,
} from "@frontend/app/types";
import { ContactConfiguration, Contact } from "@entities/types";

import {
  SET_ATTACHMENTS,
  SET_CLAIM,
  SET_COLLECTED_CUSTOMER_SCORES,
  SET_CONTACTS,
  SET_CONTACT_CONFIGURATION,
  SET_DISPUTE,
  SET_DISPUTE_OPTIONS,
  SET_ERROR,
  SET_INSTALLMENT_PLAN,
  SET_INSTALLMENT_PLAN_OPTIONS,
  SET_MERCHANT,
  SET_PAYABLE_STATUS,
  SET_PAYMENT_MODALITIES,
  SET_PAYMENT_PROVIDERS,
  SET_PAYMENT_REQUEST,
  SET_PAYMENT_SETTINGS,
  SET_PLACEHOLDERS,
  SET_REQUESTED_MODALITIES,
  SET_SEPA_TICKET,
} from "../../actionTypes";
import claimReducer from "./claim";
import authDataReducer from "./auth";

export const initialState: ViewData = {
  error: null,
  claim: null,
  merchant: null,
  sepaTicket: null,
  disputeOptions: null,
  disputeReason: null,
  collectedCustomerScores: null,
  paymentProviders: null,
  paymentSettings: null,
  paymentModalities: null,
  payableStatus: null,
  requestedModalities: null,
  installmentPlan: null,
  installmentPlanOptions: null,
  authData: null,
  attachments: null,
  placeholders: <PlaceHolder>{},
  paymentRequest: null,
  contactConfigurations: null,
  contacts: null,
};

interface MerchantAction {
  type: typeof SET_MERCHANT;
  payload: ViewData["merchant"];
}
interface ClaimAction {
  type: typeof SET_CLAIM;
  payload: ViewData["claim"];
}
interface ErrorAction {
  type: typeof SET_ERROR;
  payload: ViewData["error"];
}
interface DisputeAction {
  type: typeof SET_DISPUTE;
  payload: ViewData["disputeReason"];
}
interface DisputeOptionAction {
  type: typeof SET_DISPUTE_OPTIONS;
  payload: ViewData["disputeOptions"];
}
interface SepaTicketAction {
  type: typeof SET_SEPA_TICKET;
  payload: ViewData["sepaTicket"];
}

interface CollectedCustomerScoreAction {
  type: typeof SET_COLLECTED_CUSTOMER_SCORES;
  payload: ViewData["collectedCustomerScores"];
}
interface PaymentModalitiesAction {
  type: typeof SET_PAYMENT_MODALITIES;
  payload: ViewData["paymentModalities"];
}
interface RequestModalitiesAction {
  type: typeof SET_REQUESTED_MODALITIES;
  payload: ViewData["requestedModalities"];
}
interface PaymentProviderAction {
  type: typeof SET_PAYMENT_PROVIDERS;
  payload: ViewData["paymentProviders"];
}
interface PaymentSettingAction {
  type: typeof SET_PAYMENT_SETTINGS;
  payload: ViewData["paymentSettings"];
}
interface PayableStatusAction {
  type: typeof SET_PAYABLE_STATUS;
  payload: ViewData["payableStatus"];
}
interface PlaceholderAction {
  type: typeof SET_PLACEHOLDERS;
  payload: ViewData["placeholders"];
}
interface PaymentRequestAction {
  type: typeof SET_PAYMENT_REQUEST;
  payload: ViewData["paymentRequest"];
}
interface AttachmentAction {
  type: typeof SET_ATTACHMENTS;
  payload: ViewData["attachments"];
}
interface InstallmentPlanAction {
  type: typeof SET_INSTALLMENT_PLAN;
  payload: ViewData["installmentPlan"];
}
interface InstallmentPlanOptionAction {
  type: typeof SET_INSTALLMENT_PLAN_OPTIONS;
  payload: ViewData["installmentPlanOptions"];
}

interface ContactConfigurationAction {
  type: typeof SET_CONTACT_CONFIGURATION;
  payload: ViewData["contactConfigurations"];
}

interface ContactAction {
  type: typeof SET_CONTACTS;
  payload: ViewData["contacts"];
}

type ViewDataAction =
  | MerchantAction
  | ClaimAction
  | ErrorAction
  | DisputeAction
  | DisputeOptionAction
  | SepaTicketAction
  | CollectedCustomerScoreAction
  | PaymentModalitiesAction
  | RequestModalitiesAction
  | PaymentProviderAction
  | PaymentSettingAction
  | PayableStatusAction
  | PlaceholderAction
  | PaymentRequestAction
  | AttachmentAction
  | InstallmentPlanAction
  | InstallmentPlanOptionAction
  | ContactConfigurationAction
  | ContactAction;

function getPaymentProviders(actionPayload): PaymentProviderConfiguration[] {
  return actionPayload.reduce((prev, paymentProvider: PaymentProviderConfiguration) => {
    const { name, methods } = paymentProvider;
    if (methods?.length) {
      methods.forEach((method) => {
        const [part1, part2] = method.split("_");
        const methodCode = part2 ? `${part1[0]}${part2[0]}` : part1;
        const providerKey = `${name}_${methodCode}`.toUpperCase();
        prev.push({ ...paymentProvider, method, key: providerKey });
      });
    } else {
      prev.push({ ...paymentProvider, key: name.toUpperCase() });
    }
    return prev;
  }, []);
}

export default function DataSourceReducer(state = initialState, action: ViewDataAction): ViewData {
  const { payload: actionPayload } = action;
  const payloadAsObject = actionPayload ?? {};
  const payloadAsArray = actionPayload ?? [];

  switch (action.type) {
    case SET_ERROR: {
      const { payload: error } = action;
      if (!error) {
        return { ...state, error };
      }

      const { message, name, code, textCode, level, meta, details } = <ViewDataError>payloadAsObject;
      return { ...state, error: { message, name, code, textCode, level, meta, details } };
    }
    case SET_MERCHANT: {
      return { ...state, merchant: <Merchant>payloadAsObject };
    }

    case SET_DISPUTE: {
      return { ...state, disputeReason: <Dispute>payloadAsObject };
    }

    case SET_DISPUTE_OPTIONS: {
      return { ...state, disputeOptions: <DisputeOption[]>payloadAsArray };
    }

    case SET_COLLECTED_CUSTOMER_SCORES: {
      return { ...state, collectedCustomerScores: <CollectedCustomerScore[]>payloadAsArray };
    }

    case SET_PAYMENT_MODALITIES: {
      return { ...state, paymentModalities: <PaymentModality[]>payloadAsArray };
    }

    case SET_REQUESTED_MODALITIES: {
      return { ...state, requestedModalities: <PaymentModalityRequest>actionPayload };
    }

    case SET_INSTALLMENT_PLAN: {
      return { ...state, installmentPlan: <InstallmentPlan>actionPayload };
    }

    case SET_INSTALLMENT_PLAN_OPTIONS: {
      return { ...state, installmentPlanOptions: <InstallmentPlanOptions>actionPayload };
    }

    case SET_SEPA_TICKET: {
      const providers = <PaymentProviderConfiguration[]>actionPayload;
      if (!providers) {
        return { ...state, sepaTicket: {} };
      }

      const sepaTransfer = providers.find((provider) => provider.name === "sepaTransfer");
      if (!sepaTransfer) {
        return { ...state, sepaTicket: {} };
      }

      const { configuration } = sepaTransfer;
      return { ...state, sepaTicket: configuration };
    }

    case SET_PAYMENT_PROVIDERS: {
      if (!actionPayload) {
        return { ...state, paymentProviders: [] };
      }

      const paymentProviders = getPaymentProviders(actionPayload);
      return { ...state, paymentProviders };
    }

    case SET_PAYMENT_SETTINGS: {
      return { ...state, paymentSettings: <PaymentSettings>payloadAsObject };
    }

    case SET_PAYABLE_STATUS: {
      return { ...state, payableStatus: <PayableStatus>payloadAsObject };
    }

    case SET_PLACEHOLDERS: {
      return { ...state, placeholders: <PlaceHolder>payloadAsObject };
    }

    case SET_PAYMENT_REQUEST: {
      const paymentRequest = <PaymentRequest>payloadAsObject;
      const { amount, currency, paymentAdditionalInfo: payload, paymentConfiguration: configuration } = paymentRequest;

      return {
        ...state,
        paymentRequest,
        configuration,
        payload: { amount, currency, ...payload },
      };
    }

    case SET_ATTACHMENTS: {
      return { ...state, attachments: <ViewDataAttachment[]>payloadAsArray };
    }

    case SET_CONTACT_CONFIGURATION: {
      return { ...state, contactConfigurations: <ContactConfiguration[]>payloadAsArray };
    }

    case SET_CONTACTS: {
      return { ...state, contacts: <Contact[]>payloadAsArray };
    }

    default:
      return {
        ...state,
        claim: claimReducer(state.claim, action),
        authData: authDataReducer(state.authData, action),
      };
  }
}
