import { useSelector } from "react-redux";
import _isEqual from "lodash/isEqual";
import { useContext } from "react";
import { RootState } from "@frontend/app/store/reducers";

import { LocalizationContext } from "../context";

export default function usePlaceholderResolver(computedPlaceholders = {}) {
  const viewData = useSelector((state: RootState) => state.viewData, _isEqual);
  const pageToken = useSelector(({ appConfig }: RootState) => appConfig.pageToken);

  const { placeholderService, logService } = useContext(LocalizationContext);

  const { placeholders, ...unformattedData } = viewData;
  const defaultPlaceholders = { customField: {} };
  const formattedData = { ...defaultPlaceholders, ...placeholders, ...computedPlaceholders };

  return (values) =>
    values.map((value) => {
      const { resolvedText, warning } = placeholderService.resolve(value, { formattedData, unformattedData });
      if (warning) {
        logService.logUnresolvedPlaceholders({
          warning: { ...warning, unresolvedText: value },
          pageToken,
        });
      }

      return resolvedText;
    });
}
