import "isomorphic-fetch";
import { CustomerFeedback } from "@entities/types";

export default class ApiService {
  private csrfToken: string;

  private sandbox: boolean | undefined;

  private hostUrl: string;

  constructor({ hostUrl, csrfToken, sandbox }: { hostUrl: string; csrfToken: string; sandbox?: boolean }) {
    this.csrfToken = csrfToken;
    this.sandbox = sandbox;
    this.hostUrl = sandbox ? `/webapi/sandbox/lp` : hostUrl;
  }

  /**
   * Requests public API
   * @param {string} uri
   * @param {object} [payload]
   * @param {string} [method='POST']
   * @returns {Promise<object>}
   */
  async request(uri: string, payload = {}, method = "POST") {
    const options = {
      method,
      body: JSON.stringify(payload),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "csrf-token": this.csrfToken,
      },
      credentials: "include" as RequestCredentials,
    };
    const response = await fetch(`${this.hostUrl}${uri}`, options);
    if (!response.ok) {
      const responseData = await getErrorData(response);
      const error = new Error("Failed to request public API") as any;
      error.innerError = responseData;
      error.code = responseData.code;
      error.details = {
        statusText: responseData.statusText,
        status: responseData.status,
      };

      throw error;
    }

    return response.json();
  }

  performAuth(pageToken, payload = {}) {
    if (this.sandbox) {
      return null;
    }

    return this.request(`/api/auth/${pageToken}`, payload);
  }

  fetchViewModel({ locale, pageToken, merchantId, useCase }, payload = {}) {
    if (this.sandbox) {
      return this.request(`/${merchantId}/api/view-model?locale=${locale}&useCase=${useCase}`, payload);
    }

    return this.request(`/api/view-model/${pageToken}?locale=${locale}`, payload);
  }

  submitContactData({ pageToken, locale }, payload) {
    if (!pageToken || !locale || !payload || this.sandbox) {
      return null;
    }
    return this.request(`/api/collect-contact-data/${pageToken}?locale=${locale}`, payload);
  }

  async resendVerificationToken({ pageToken, locale }, payload) {
    if (!pageToken || !locale || !payload || this.sandbox) {
      return null;
    }
    return this.request(`/api/resend-verification-token/${pageToken}?locale=${locale}`, payload);
  }

  async validateContact({ pageToken, locale }, payload) {
    if (!pageToken || !locale || !payload || this.sandbox) {
      return null;
    }
    return this.request(`/api/validate-contact/${pageToken}?locale=${locale}`, payload);
  }

  submitFeedback({ pageToken, locale }, payload: CustomerFeedback) {
    if (!pageToken || !locale || !payload || this.sandbox) {
      return null;
    }
    return this.request(`/api/submit-feedback/${pageToken}?locale=${locale}`, payload);
  }

  async log(message, type, context, pageToken) {
    try {
      if (this.sandbox) {
        return;
      }

      await this.request(`/api/log/${pageToken}`, { message, type, context });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

async function getErrorData(response) {
  const { headers, status, statusText } = response;
  const contentType = typeof headers.get === "function" ? headers.get("content-type") : "";

  if (contentType.includes("application/json")) {
    const jsonData = await response.json();
    return { status, statusText, ...(jsonData.result ?? jsonData) };
  }

  return { status, statusText, code: "INTERNAL" };
}
