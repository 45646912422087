import React, { useContext, ReactNode } from "react";
import classnames from "classnames";

import { ThemeContext } from "@frontend/app/context";
import useTranslation, { TranslationKey } from "@frontend/app/hooks/useTranslation";
import Text from "@frontend/app/components/Text";

type MessageProps = {
  children?: ReactNode;
  primaryText: TranslationKey | TranslationKey[];
  secondaryText?: TranslationKey;
  emphasizedText?: TranslationKey;
  computedPlaceholders?: Record<string, string | number>;
};

const Message: React.FC<MessageProps> = ({
  children,
  primaryText,
  secondaryText,
  emphasizedText,
  computedPlaceholders,
}) => {
  const { classNames } = useContext(ThemeContext);
  const messages = ([] as TranslationKey[]).concat(primaryText);
  const translation = useTranslation(computedPlaceholders);

  return (
    <section className="message main__message">
      <div className={classnames("message__section main__message--wrapper", classNames.text)}>
        {children}
        {messages.map((message, idx) => {
          const key = `mainMessage${idx}`;
          return (
            <div key={key} className={classnames("text", "main__message__body")}>
              <Text translationKey={message} />
            </div>
          );
        })}
        {emphasizedText && translation(emphasizedText) && (
          <div className={classnames("text-emphasized", "main__message__body__emphasized", classNames.textEmphasized)}>
            <Text translationKey={emphasizedText} computedPlaceholders={computedPlaceholders} />
          </div>
        )}
        {secondaryText && translation(secondaryText) && (
          <div className={classnames("text-emphasized", "main__message__body__emphasized", classNames.textEmphasized)}>
            <Text translationKey={secondaryText} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Message;
