import React, { useContext, useEffect } from "react";

import { Maybe } from "@entities";
import useTranslation, { TranslationKey } from "@frontend/app/hooks/useTranslation";

import MainMessage from "../components/MainMessage";
import { ThemeContext } from "../context";
import { ViewDataError } from "../types";

type Props = {
  error: Maybe<ViewDataError>;
};

const errorCodeMap = {
  AUTH_MAX_ATTEMPTS: "ERROR_MAX_LOGIN_ATTEMPTS",
};

const getTextErrorCode = ({ code, textCode } = {} as any) => {
  if (errorCodeMap[code]) {
    return errorCodeMap[code];
  }

  return textCode || code;
};

const ErrorPage: React.FC<Props> = ({ error }) => {
  const {
    classNames: { main },
    actions,
  } = useContext(ThemeContext);
  const className = "main--error";
  const translation = useTranslation();
  useEffect(() => {
    if (!main.includes(className)) {
      actions.addClassName("main", className);
    }
  }, []);

  const { message } = error ?? ({} as ViewDataError);
  const textCode = getTextErrorCode(error);

  // TODO: after migration all errors to BaseError, only code should be used
  const titleText =
    translation(textCode) || translation(`${message}_TITLE` as TranslationKey) || "ERROR_INTERNAL_TITLE";
  const description = translation(message as TranslationKey) || translation(textCode) || translation("ERROR_INTERNAL");

  return (
    <MainMessage titleText={titleText} primaryText={description} secondaryText="ERROR_MAIN_MESSAGE_BODY_EMPHASIZED" />
  );
};

export default ErrorPage;
