import "url-search-params-polyfill";
import { useEffect } from "react";
import { PageType } from "@entities/enums";

const storageKey = "page-load";
const noCascadeEffectParam = "noc";

type EventPayload = {
  pageToken: string;
  pageType?: string;
  useCase?: string;
  referenceNumber?: string;
};

export default function useBrowserTabSync({ pageToken, pageType, referenceNumber, useCase }: EventPayload): void {
  useEffect(() => {
    if (!window || !pageToken || !pageType || !referenceNumber || !useCase) {
      return;
    }

    try {
      const url = new URL(window.location.href);

      if (!url.searchParams.has(noCascadeEffectParam)) {
        triggerStorageEvent({ pageToken, pageType, referenceNumber, useCase });
      } else {
        url.searchParams.delete(noCascadeEffectParam);
        const newUrl = url.toString();
        window.history?.pushState({ path: newUrl }, "", newUrl);
      }

      window.addEventListener("storage", ({ key, newValue }) => {
        if (key === storageKey && newValue) {
          const payload: EventPayload = JSON.parse(newValue) || {};

          const differentUseCaseForPageLoaded = payload.pageToken === pageToken && payload.useCase !== useCase;

          const newPaymentPageLoaded =
            payload.pageType === PageType.PAYMENT &&
            payload.pageType === pageType &&
            payload.referenceNumber === referenceNumber &&
            payload.pageToken !== pageToken;

          if (differentUseCaseForPageLoaded || newPaymentPageLoaded) {
            url.searchParams.set(noCascadeEffectParam, "");
            window.location.href = url.href;
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`useBrowserTabSync`, error);
    }
  }, []);
}

function triggerStorageEvent({ pageToken, pageType, referenceNumber, useCase }: EventPayload): void {
  localStorage.setItem(storageKey, JSON.stringify({ pageToken, pageType, referenceNumber, useCase }));
  localStorage.removeItem(storageKey);
}
