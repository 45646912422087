export const SET_COMPONENTS = "SET_COMPONENTS";
export const SET_APP_CONFIG = "SET_APP_CONFIG";
export const SET_ERROR = "SET_ERROR";
export const SET_PAYMENT_PROVIDERS = "SET_PAYMENT_PROVIDERS";
export const SET_SEPA_TICKET = "SET_SEPA_TICKET";
export const SET_CLAIM = "SET_CLAIM";
export const SET_MERCHANT = "SET_MERCHANT";
export const SET_DISPUTE = "SET_DISPUTE";
export const SET_PAYMENT_MODALITIES = "SET_PAYMENT_MODALITIES";
export const SET_REQUESTED_MODALITIES = "SET_REQUESTED_MODALITIES";
export const SET_DISPUTE_OPTIONS = "SET_DISPUTE_OPTIONS";
export const SET_COLLECTED_CUSTOMER_SCORES = "SET_COLLECTED_CUSTOMER_SCORES";
export const SET_PAYMENT_SETTINGS = "SET_PAYMENT_SETTINGS";
export const SET_PAYABLE_STATUS = "SET_PAYABLE_STATUS";
export const SET_PLACEHOLDERS = "SET_PLACEHOLDERS";
export const SET_ATTACHMENTS = "SET_ATTACHMENTS";
export const SET_INSTALLMENT_PLAN_OPTIONS = "SET_INSTALLMENT_PLAN_OPTIONS";
export const SET_INSTALLMENT_PLAN = "SET_INSTALLMENT_PLAN";
export const SET_CONTACT_CONFIGURATION = "SET_CONTACT_CONFIGURATION";
export const SET_CONTACTS = "SET_CONTACTS";

export const TOGGLE_CLAIM_ITEM = "TOGGLE_CLAIM_ITEM";
export const SET_PAYMENT_REQUEST = "SET_PAYMENT_REQUEST";

export const CALCULATE_CLAIM_TOTAL = "CALCULATE_CLAIM_TOTAL";

export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";

export const OPEN_DISPUTE_CLAIM_MODAL = "OPEN_DISPUTE_CLAIM_MODAL";
export const CLOSE_DISPUTE_CLAIM_MODAL = "CLOSE_DISPUTE_CLAIM_MODAL";
export const OPEN_PAYMENT_MODALITIES_MODAL = "OPEN_PAYMENT_MODALITIES_MODAL";
export const CLOSE_PAYMENT_MODALITIES_MODAL = "CLOSE_PAYMENT_MODALITIES_MODAL";
export const OPEN_FINTEC_SYSTEMS_FOOTER_MODAL = "OPEN_FINTEC_SYSTEMS_FOOTER_MODAL";
export const CLOSE_FINTEC_SYSTEMS_FOOTER_MODAL = "CLOSE_FINTEC_SYSTEMS_FOOTER_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const TOGGLE_CLAIM_DETAILS = "TOGGLE_CLAIM_DETAILS";
