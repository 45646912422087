import React from "react";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";
import _isEqual from "lodash/isEqual";
import { Theme } from "@entities/enums";
import { RootState } from "@frontend/app/store/reducers";
import ErrorBoundary from "../components/ErrorBoundary";

const EprimoThemeLayout = loadable(() => import("./eprimo/v2/Layout"));
const DunningThemeLayout = loadable(() => import("./dunning/Layout"));
const OttoThemeLayout = loadable(() => import("./otto/Layout"));
const DefaultWhitelabelThemeLayout = loadable(() => import("./DefaultWhitelabelLayout"));

const BaseLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { themeName, error } = useSelector(mapState, _isEqual);
  const ThemeLayout = getThemeLayout(themeName);

  return (
    <ThemeLayout>
      <ErrorBoundary error={error}>{children}</ErrorBoundary>
    </ThemeLayout>
  );
};

function mapState({ theme: { name: themeName }, viewData: { error } }: RootState) {
  return { themeName, error };
}

function getThemeLayout(themeName: string) {
  switch (themeName) {
    case Theme.OTTO:
      return OttoThemeLayout;
    case Theme.DUNNING:
      return DunningThemeLayout;
    case Theme.EPRIMO:
      return EprimoThemeLayout;
    default:
      return DefaultWhitelabelThemeLayout;
  }
}

export default BaseLayout;
