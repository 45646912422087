import { useEffect } from "react";

declare global {
  interface Window {
    ineum: (...args: (string | number)[]) => void;
  }
}

export default function useInstana({ pageToken, pageType, useCase, key, traceId } = {} as any) {
  useEffect(() => {
    if (!window || !key || !pageToken) {
      return;
    }

    try {
      const page = [pageType, useCase].filter((v) => !!v).join(":");

      // @ts-ignore
      appendScript();
      setupScript({
        key,
        traceId,
        page,
        reportingUrl: "https://eum-blue-saas.instana.io",
        user: pageToken,
        // ignore FTS external errors
        ignoreErrorMessages: [/TypeError: Cannot read property 'data' of undefined/i],
      });
      window.ineum("meta", "version", 2);
      window.ineum("trackSessions");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);
}

function setupScript(options) {
  Object.keys(options).forEach((optKey) => {
    const value = options[optKey];
    if (!value) {
      return;
    }

    window.ineum(optKey, value);
  });
}

/* eslint-disable */
function appendScript(s = window, t = "InstanaEumObject", a = "ineum", n, i, d) {
  s[t] ||
    ((s[t] = a),
    (n = s[a] =
      function () {
        n.q.push(arguments);
      }),
    (n.q = []),
    (n.v = 2),
    // @ts-ignore
    (n.l = 1 * new Date()),
    (i = document.createElement("script")),
    (i.defer = true),
    (i.src = "https://eum.instana.io/eum.min.js"),
    i.setAttribute("crossorigin", "anonymous"),
    (d = document.getElementsByTagName("script")[0]),
    d.parentNode.insertBefore(i, d));
}
/* eslint-enable */
