import _merge from "lodash/merge";
import { findUnresolvableKeys, getPlaceholders, resolve } from "@common/textSnippetResolver";

export default class PlaceholderService {
  private formatterService: any;

  constructor({ formatterService }) {
    this.formatterService = formatterService;
  }

  // TBD: Should all the data be unformatted?
  resolve(translation, { formattedData, unformattedData }) {
    if (!translation || typeof translation !== "string") {
      return { resolvedText: translation, warning: null };
    }

    const placeholders = getPlaceholders(translation);
    if (!placeholders.length) {
      // if there are not placeholders - skip formatter and resolver
      return { resolvedText: translation, warning: null };
    }

    const newData = this.formatterService.formatData(placeholders, unformattedData);
    const dependencyData = _merge(formattedData, newData);
    const renderedText = resolve(translation, dependencyData);
    const unresolvableKeys = findUnresolvableKeys(renderedText, dependencyData);

    // Keep track of all the snippets that have unresolvable keys
    if (unresolvableKeys && unresolvableKeys.length) {
      // TODO: should not have configType but the useCase
      const { error: errorData, type: configType } = dependencyData;
      return { resolvedText: renderedText, warning: { errorData, configType, unresolvableKeys } };
    }

    return { resolvedText: renderedText, warning: null };
  }
}
