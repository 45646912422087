import React, { useContext, useEffect } from "react";
import classnames from "classnames";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";
import _isEqual from "lodash/isEqual";
import { PageType, UseCase } from "@entities/enums";
import { RootState } from "@frontend/app/store/reducers";
import { ThemeContext } from "../context";

const PassCodePage = loadable(() => import("./PassCodePage"));
const AuthPage = loadable(() => import("./AuthPage"));
const ErrorPage = loadable(() => import("./ErrorPage"));
const DisputeResolvedPage = loadable(() => import("./DisputeResolvedPage"));
const FailurePage = loadable(() => import("./FailurePage"));
const ClaimPaymentSuccessPage = loadable(() => import("./Confirmation"));
const ClaimProgressPage = loadable(() => import("./Progress"));
const PaymentPage = loadable(() => import("./PaymentPage"));
const ContactCollectionEmailProgressPage = loadable(() => import("./ContactCollection/EmailProgressPage"));
const ContactCollectionMobileProgressPage = loadable(() => import("./ContactCollection/MobileProgressPage"));
const ContactCollectionSuccessPage = loadable(() => import("./ContactCollection/SuccessPage"));
const ContactCollectionInvalidPage = loadable(() => import("./ContactCollection/InvalidPage"));

const BasePage: React.FC = () => {
  const { pageType, useCase, claimStatus } = useSelector(mapState, _isEqual);

  const mainClassName = getMainClassName(pageType, useCase, claimStatus);
  const className = getPageClassName(pageType, useCase);

  const { actions, classNames } = useContext(ThemeContext);
  const { main } = classNames;

  useEffect(() => {
    if (mainClassName && !main.includes(mainClassName)) {
      actions.addClassName("main", mainClassName);
    }
  }, [main, actions, mainClassName]);
  const Page = getPage(pageType, useCase);

  return (
    <div className={className}>
      <Page />
    </div>
  );
};

function mapState({ translations, layout: { options = {} as any }, viewData: { claim } }: RootState) {
  return { translations, pageType: options.pageType, useCase: options.useCase, claimStatus: claim?.status };
}

function getPage(pageType: PageType, useCase: UseCase) {
  const useCasePageMap = {
    [UseCase.PASSCODE]: PassCodePage,
    [UseCase.AUTHENTICATION]: AuthPage,
    [UseCase.ERROR]: ErrorPage,
    [UseCase.DISPUTE_RESOLVED]: new Map<PageType, any>([[PageType.CLAIM, DisputeResolvedPage]]),
    [UseCase.FAILURE]: new Map<PageType, any>([
      [PageType.CLAIM, FailurePage],
      [PageType.CONTACT, ContactCollectionInvalidPage],
    ]),
    [UseCase.SUCCESS]: new Map<PageType, any>([
      [PageType.CLAIM, ClaimPaymentSuccessPage],
      [PageType.PAYMENT, ClaimPaymentSuccessPage],
      [PageType.CONTACT, ContactCollectionSuccessPage],
    ]),
    [UseCase.PROGRESS]: new Map<PageType, any>([
      [PageType.CLAIM, ClaimProgressPage],
      [PageType.PAYMENT, PaymentPage],
    ]),
    [UseCase.PROGRESS_EMAIL]: new Map<PageType, any>([[PageType.CONTACT, ContactCollectionEmailProgressPage]]),
    [UseCase.PROGRESS_MOBILE]: new Map<PageType, any>([[PageType.CONTACT, ContactCollectionMobileProgressPage]]),
  };

  const page = useCasePageMap[useCase];

  if (page instanceof Map) {
    return page.get(pageType) || ErrorPage;
  }

  return page || ErrorPage;
}

function getMainClassName(pageType: PageType, useCase: UseCase, claimStatus?: string) {
  if (pageType === PageType.CLAIM) {
    const suffix =
      (useCase === UseCase.DISPUTE_RESOLVED && "success") ||
      (claimStatus === "DISPUTE" && useCase === UseCase.PROGRESS ? "disputed" : useCase);
    return `main--${suffix}`;
  }

  if (pageType === PageType.CONTACT || pageType === PageType.PAYMENT) {
    return `main--${useCase}`;
  }

  return undefined;
}

function getPageClassName(pageType: PageType, useCase: UseCase) {
  if (useCase === UseCase.PASSCODE) {
    return "pass-code-page";
  }

  switch (pageType) {
    case PageType.CLAIM:
      return classnames("claim-layout", "main__description clearfix");
    case PageType.PAYMENT:
      return classnames("payment-layout", "main__description clearfix");
    case PageType.CONTACT:
      return classnames("contact-collection-page-layout", "main__description clearfix");
    default:
      return undefined;
  }
}

export default BasePage;
