import renderTemplateString from "es6-template-strings";
import get from "lodash/get";
import placeholdersService from "@collectai/placeholders";

import flatten from "./flatten-object";

const containsConditionalOperatorRegex = /&&|\|\||\?[^:]*:?/;

export function getPlaceholders(translation: string): string[] {
  return Object.keys(placeholdersService.find(translation)) || [];
}

export function findUnresolvableKeys(translation = "", textData = {}): string[] {
  const placeholders = getPlaceholders(translation);
  // Getting all the variables inside the placeholders
  const keyPaths = placeholders.reduce((pV, placeholder) => {
    pV.add(placeholder);
    return pV;
  }, new Set<string>());

  return [...keyPaths].filter((keyPath) => !get(textData, keyPath));
}

export function resolve(translation = "", textData = {}, nestedResolution = false): string {
  try {
    const replacedText = placeholdersService.replace(translation, flatten(textData));

    const unresolvedConditionalPlaceholders = getPlaceholders(replacedText).filter((placeholder) =>
      containsConditionalOperatorRegex.test(placeholder)
    );
    // TODO: Remove renderTemplateString/es6-template-strings once placeholderService supports conditionals
    const renderedText = (
      unresolvedConditionalPlaceholders.length ? renderTemplateString(replacedText, textData) : replacedText
    )
      // if data contains null - template resolves to a string with null inside
      // TODO: looks like dirty hack, should be changed when LP placeholders migrated to CMS
      .replace(/null/g, "");

    const unresolvedPlaceholders = getPlaceholders(renderedText);

    if (nestedResolution || !unresolvedPlaceholders.length) {
      return renderedText;
    }

    return resolve(renderedText, textData, true);
  } catch (error) {
    return translation;
  }
}

export default { resolve, findUnresolvableKeys, getPlaceholders };
