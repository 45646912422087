import { combineReducers } from "redux";
import { Dict } from "@entities";

import appConfig from "./appConfig";
import components from "./components";
import viewData from "./viewData";
import modal from "./modal";
import theme from "./theme";
import layout from "./layout";

const combinedReducers = combineReducers({
  appConfig,
  layout,
  components,
  viewData,
  theme,
  formats: (state = []) => state,
  translations: (state: Dict<string> = {}) => state,
  modal,
});

export type RootState = ReturnType<typeof combinedReducers>;

export default combinedReducers;
