const flattenObject = (obj: any, roots: string[] = []) =>
  Object
    // find props of given object
    .keys(obj)
    // return an object by iterating props
    .reduce(
      (memo, prop) => ({
        ...memo,
        ...(Object.prototype.toString.call(obj[prop]) === "[object Object]"
          ? // keep working if value is an object
            flattenObject(obj[prop], roots.concat([prop]))
          : // include current prop and value and prefix prop with the roots
            { [roots.concat([prop]).join(".")]: obj[prop] }),
      }),
      {}
    );
export default flattenObject;
