import { SET_AUTH_ERROR, CLEAR_AUTH_ERROR } from "../../actionTypes";

export default (state = {} as any, action = {} as any) => {
  switch (action.type) {
    case SET_AUTH_ERROR: {
      const { payload: error } = action;

      return {
        ...state,
        error: {
          code: error.code,
        },
      };
    }

    case CLEAR_AUTH_ERROR: {
      return { ...state, error: null };
    }

    default:
      return state;
  }
};
