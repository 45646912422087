/* eslint-disable @typescript-eslint/no-var-requires */
const hash = require("object-hash");

const loggedWarnings: string[] = [];

class LogService {
  private apiService: any;

  constructor({ apiService }) {
    this.apiService = apiService;
  }

  /**
   * Logs a warning in the service
   * @param {object} data
   * @param {object} data.warning Warning data that will be added as context of the log
   * @param {string} data.pageToken Page identifier
   * @returns {Promise<object>}
   */
  async logUnresolvedPlaceholders({ warning, pageToken }) {
    // Only use logger service in client side
    if (!pageToken || typeof window === "undefined") return;

    const warningHash: string = hash(warning);

    if (!loggedWarnings.includes(warningHash)) {
      loggedWarnings.push(warningHash);
      this.apiService.log("Something went wrong when resolving one of the text snippets", "warn", warning, pageToken);
    }
  }
}

export default LogService;
