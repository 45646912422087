import { Maybe } from "@entities";
import React, { Component, ReactNode } from "react";
import ErrorPage from "../pages/ErrorPage";
import { ViewDataError } from "../types";

type Props = {
  error: Maybe<ViewDataError>;
  children: ReactNode;
};

type State = {
  error?: ViewDataError;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  // There's not yet a react hook to handle this function
  static getDerivedStateFromError(error): State {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    if (typeof (window as any).ineum !== "undefined") {
      (window as any).ineum("reportError", error, {
        componentStack: errorInfo.componentStack,
      });
    }
  }

  render() {
    const { error: propsError } = this.props;
    const { error: stateError } = this.state;
    const error = stateError || propsError;
    if (error) {
      return <ErrorPage error={error} />;
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
