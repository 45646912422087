type ThemeState = {
  name: string;
  customCss?: string;
  customJs?: string;
};

export const themeInitialState: ThemeState = {
  name: "",
};

export default (state = themeInitialState) => {
  return state;
};
