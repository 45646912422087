import { useSelector, shallowEqual } from "react-redux";
import _isEqual from "lodash/isEqual";
import { useContext } from "react";
import { RootState } from "@frontend/app/store/reducers";
import { DefaultTranslationKey } from "@backend/website/locales/pages";

import { LocalizationContext } from "../context";

export type TranslationKey =
  | DefaultTranslationKey
  | "MAIN_ACTIONS_PAYMENT_BALANCE_DESCRIPTION"
  | "MAIN_HEADER_ACCOUNT_LINK"
  | "MAIN_HEADER_ACCOUNT_ICON"
  | "MAIN_HEADER_ACCOUNT_LABEL"
  | "ERROR_MAIN_MESSAGE_BODY_EMPHASIZED"
  | "DEFERMENT_FORM_FINE_PRINT";

export default function useTranslation(computedPlaceholders = {}) {
  const viewData = useSelector((state: RootState) => state.viewData, _isEqual);
  const translations = useSelector((state: RootState) => state.translations, shallowEqual);
  const pageToken = useSelector(({ appConfig }: RootState) => appConfig.pageToken);

  const { placeholderService, logService } = useContext(LocalizationContext);

  const { placeholders, ...unformattedData } = viewData;
  const defaultPlaceholders = { customField: {} };
  const formattedData = { ...defaultPlaceholders, ...placeholders, ...computedPlaceholders };

  return (
    input: TranslationKey | TranslationKey[],
    inputComputedPlaceholders: Record<string, string | number> = {}
  ) => {
    const translationKeys = Array.isArray(input) ? input : [input];

    const result = translationKeys.map((translationKey) => {
      const translation = translations[translationKey];

      const { resolvedText, warning } = placeholderService.resolve(translation, {
        formattedData: { ...formattedData, ...inputComputedPlaceholders },
        unformattedData,
      });

      if (warning) {
        logService.logUnresolvedPlaceholders({
          warning: { ...warning, key: translationKey, unresolvedText: translations[translationKey] },
          pageToken,
        });
      }

      return resolvedText;
    });

    if (Array.isArray(input)) {
      return result;
    }

    return result[0];
  };
}
