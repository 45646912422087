import { NullableDict } from "@entities";
import { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { APIContext } from "../context";

export default function useDataLoader(reduxActions, dependencies: NullableDict[] = [], isComponentEnabled = true) {
  const [loading, setLoading] = useState(true);
  const { apiService } = useContext(APIContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isComponentEnabled && isMissingData(dependencies)) {
      setLoading(true);
      reduxActions.forEach((reduxAction) => dispatch(reduxAction(apiService)));
      return;
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (isComponentEnabled) {
      setLoading(isMissingData(dependencies));
    }
  }, dependencies);

  return loading;
}

function isMissingData(dependencies: NullableDict[] = []) {
  return dependencies.some((dependency) => !dependency);
}
