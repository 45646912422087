import BigNumber from "bignumber.js";
import _find from "lodash/find";

import { SET_CLAIM, TOGGLE_CLAIM_ITEM, CALCULATE_CLAIM_TOTAL } from "../../actionTypes";

export default (baseState = {} as any, action = {} as any) => {
  const state = baseState || ({} as any);

  switch (action.type) {
    case SET_CLAIM: {
      const { payload: claim } = action;
      if (!claim) {
        return state;
      }

      const items = claim.items?.map((item) => ({ ...item, enabled: true }));
      return { ...state, items, selectedAmount: parseInt(claim.outstanding, 10) };
    }

    case TOGGLE_CLAIM_ITEM: {
      const { itemId, enabled } = action.payload;
      const items = [...state.items];
      const item = _find(items, (claimItem) => claimItem.id === itemId);
      item.enabled = enabled;

      return { ...state, items };
    }

    case CALCULATE_CLAIM_TOTAL: {
      const { items } = state;
      const newAmount = items
        .reduce((pV, { enabled, outstanding }) => (enabled ? pV.plus(outstanding) : pV), new BigNumber(0))
        .toNumber();
      return { ...state, selectedAmount: newAmount };
    }

    default:
      return state;
  }
};
