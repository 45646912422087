import {
  OPEN_DISPUTE_CLAIM_MODAL,
  CLOSE_DISPUTE_CLAIM_MODAL,
  OPEN_PAYMENT_MODALITIES_MODAL,
  CLOSE_PAYMENT_MODALITIES_MODAL,
  OPEN_FINTEC_SYSTEMS_FOOTER_MODAL,
  CLOSE_FINTEC_SYSTEMS_FOOTER_MODAL,
  CLOSE_MODAL,
} from "../actionTypes";

export const modalInitialState = {
  disputeClaimModalIsOpen: false,
  paymentModalitiesModalIsOpen: false,
  fintecSystemsFooterModalIsOpen: false,
};

export default (state = modalInitialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case OPEN_DISPUTE_CLAIM_MODAL:
      return { ...state, disputeClaimModalIsOpen: true };
    case CLOSE_DISPUTE_CLAIM_MODAL:
      return { ...state, disputeClaimModalIsOpen: false };
    case OPEN_PAYMENT_MODALITIES_MODAL:
      return { ...state, paymentModalitiesModalIsOpen: true };
    case CLOSE_PAYMENT_MODALITIES_MODAL:
      return { ...state, paymentModalitiesModalIsOpen: false };
    case OPEN_FINTEC_SYSTEMS_FOOTER_MODAL:
      return { ...state, fintecSystemsFooterModalIsOpen: true };
    case CLOSE_FINTEC_SYSTEMS_FOOTER_MODAL:
      return { ...state, fintecSystemsFooterModalIsOpen: false };
    case CLOSE_MODAL:
      if (action.payload === "disputeModal") {
        return { ...state, disputeClaimModalIsOpen: false };
      }
      if (action.payload === "ZADLContactModalFT") {
        return { ...state, fintecSystemsFooterModalIsOpen: false };
      }
      return { ...state, paymentModalitiesModalIsOpen: false };

    default:
      return state;
  }
};
