import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

type Props = {
  children: ReactNode;
  encodeSpecialCharacters: boolean;
  defaultTitle: string;
  favIcon?: string;
};

const CustomHelmet: React.FC<Props> = ({ children, encodeSpecialCharacters, defaultTitle = "", favIcon }) => {
  return (
    <Helmet defaultTitle={defaultTitle} encodeSpecialCharacters={encodeSpecialCharacters}>
      {favIcon && <link rel="shortcut icon" type="image/png" sizes="16x16 32x32" href={favIcon} />}
      {children}
    </Helmet>
  );
};

export default CustomHelmet;
