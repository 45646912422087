import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";

const composeEnhancers =
  (typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const configureStore = ({ appConfig, translations, formats, layout, components, theme, viewData, modal } = {} as any) =>
  createStore(
    reducers,
    {
      appConfig,
      translations,
      formats,
      layout,
      components,
      theme,
      viewData,
      modal,
    },
    composeEnhancers(applyMiddleware(thunk))
  );

export type Store = ReturnType<typeof configureStore>;

export default configureStore;
