import { useSelector } from "react-redux";
import _isEqual from "lodash/isEqual";
import React from "react";
import { RootState } from "@frontend/app/store/reducers";
import { useTranslation } from "@frontend/app/hooks";
import CustomHelmet from "../Helmet";

const mapState = ({ appConfig: { baseUrl, S3AssetsURL, locale }, viewData: { claim, error } }: RootState) => {
  return {
    baseUrl,
    locale,
    claim,
    error,
    S3AssetsURL,
  };
};

const Head: React.FC = () => {
  const { baseUrl, locale, error, S3AssetsURL } = useSelector(mapState, _isEqual);
  const translation = useTranslation({ S3AssetsURL });

  const [language] = locale.split(/[-_]/);

  const [authorText, descriptionText, favIcon, defaultTitle] = translation([
    "HEAD_META_AUTHOR",
    "HEAD_META_DESCRIPTION",
    "HEAD_LINK_FAVICON",
    "HEAD_TITLE",
  ]);

  return (
    <CustomHelmet favIcon={favIcon} defaultTitle={error ? "" : defaultTitle} encodeSpecialCharacters={false}>
      <html lang={language} />
      <base href={baseUrl} />
      <meta name="author" content={authorText} />
      <meta name="description" content={descriptionText} />
      <script async src={`${S3AssetsURL}/common/libs/jquery/3.5.1/jquery.min.js`} crossOrigin="" />
      <script src={`${baseUrl}/page/statics/js/scripts.js`} />
    </CustomHelmet>
  );
};

export default Head;
