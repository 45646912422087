import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { loadableReady } from "@loadable/component";
import { Provider as ReduxProvider } from "react-redux";
import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store";
import App from "./App";

declare const window: typeof globalThis & { __PRELOADED_STATE__?: string };

loadableReady(() => {
  const container = document.getElementById("main");
  if (container) {
    const preloadedState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;

    const preloadedStateScript = document.getElementById("preloaded-state");
    if (preloadedStateScript) {
      preloadedStateScript.parentElement?.removeChild(preloadedStateScript);
    }

    const jsx = (
      <ReduxProvider store={configureStore(preloadedState)}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    );

    hydrateRoot(container, jsx);
  }
});
