import React, { useContext, ReactNode } from "react";
import { ThemeContext } from "@frontend/app/context";
import { TranslationKey } from "@frontend/app/hooks";
import Title from "./Title";
import Message from "./Message";

type Props = {
  alignText?: string;
  titleText: TranslationKey;
  titleHeader?: TranslationKey;
  primaryText: TranslationKey;
  secondaryText?: TranslationKey;
  emphasizedText?: TranslationKey;
  computedPlaceholders?: Record<string, string | number>;
  children?: ReactNode;
};

const MainMessage: React.FC<Props> = ({
  alignText,
  titleText,
  titleHeader,
  primaryText,
  secondaryText,
  emphasizedText,
  computedPlaceholders,
  children,
}) => {
  const {
    components: { MainMessage: CustomComponent },
  } = useContext(ThemeContext);

  const divStyle: React.CSSProperties = {
    ...(alignText && ({ textAlign: alignText } as any)),
  };

  return CustomComponent ? (
    <CustomComponent
      titleText={titleText}
      titleHeader={titleHeader}
      primaryText={primaryText}
      secondaryText={secondaryText}
      emphasizedText={emphasizedText}
      computedPlaceholders={computedPlaceholders}
    >
      {children}
    </CustomComponent>
  ) : (
    <div style={divStyle}>
      <Title header={titleHeader} title={titleText} />
      <Message
        primaryText={primaryText}
        secondaryText={secondaryText}
        emphasizedText={emphasizedText}
        computedPlaceholders={computedPlaceholders}
      >
        {children}
      </Message>
    </div>
  );
};

export default MainMessage;
